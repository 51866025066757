import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStateValue } from "../State"
import NsfwListingItem from "../NsfwListingItem"
import CommaList from "../../components/CommaList"
import * as style from "./PodcastListingItem.module.sass"
import clsx from "clsx"

const PodcastListingItem = ({
	title,
	isNsfw,
	contentCoverImage,
	link
}) => {

	const [state] = useStateValue()
	const showNsfwContent = state.nsfw.show

	if (isNsfw && !showNsfwContent) {

		return <NsfwListingItem /> 
	
	} 
	
	const coverImageGatsbyImage = getImage(contentCoverImage)
	const rootClassName = clsx({
		[style.PodcastListingItem]: true,
	})

	return (

		<div className={rootClassName}>

			<div className="imageContainer">

				{coverImageGatsbyImage && 
					<GatsbyImage
						alt={title}
						title={title}
						className={style.coverImage}
						image={coverImageGatsbyImage} />
				}

			</div>

			<div>

				<h3>
					{title}
				</h3>

				{isNsfw &&
					<div className={style.meta}>
						{isNsfw && <span className={style.nsfwTag}>Not safe for work</span>}
					</div>
				}

				{link &&
					<a target="_blank" href={link} rel="noreferrer">Podcast homepage</a>
				}

			</div>

		</div> 

	)


}

export default PodcastListingItem