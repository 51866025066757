import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import PodcastListing from "../../components/PodcastListing"
import "../index.sass"
import PageTransition from "../../components/PageTransition"
import PageHeader from "../../components/PageHeader/PageHeader"
import PageOverview from "../../components/PageOverview"

const InterestingPage = (props) => {

	const { data } = props
	const posts = data.allMarkdownRemark.edges

	return (

		<PageTransition {...props}>
			<PageHeader sectionName="Recommended" title="Podcasts" description="A collection of interesting podcasts" />
			<PageOverview>
				<p>A collection of podcasts often referenced or recommended within the community. These podcasts are on a wide range of subjects and a presented here as a reference. <strong>The views and opinions expressed in these podcasts are not explicitly the views and options of Deviant Robot or the community.</strong></p>
			</PageOverview>
			<PodcastListing posts={posts} />
		</PageTransition>

	)

}

InterestingPage.propTypes = {

	data: PropTypes.object.isRequired,

}

export default InterestingPage

export const pageQuery = graphql`

query {
		allMarkdownRemark(

			filter: {
				fields: {
					category: {eq: "Recommended"}, 
					collection: {eq: "Podcasts"}
				}
				frontmatter: {
					draft: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___title]
			}

		) {
			edges {
				node {
					id
					fields {
						category
					}
					frontmatter {
						title
						keywords
						abstract
						link
						contentCoverImage{
							childImageSharp {
								gatsbyImageData(
									width: 250
									placeholder: BLURRED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
	}

`